import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './Header';
import Intro from './Intro';
import Footer from './Footer';
import Loader from '../Loader';
import { allCategories } from '../providers/api';
import { useCategories } from '../providers/contextCategories';
import GoBack from './GoBack';

function Layout({ children }) {
  const { categoriesContext, selectedIdCat } = useCategories();
  const { categories, setCategories } = categoriesContext;
  const { idCat, setIdCat } = selectedIdCat;
  const [isLoading, setIsLoading] = useState(false);
  const [buttonGoBack, setButtonGoBack] = useState(false);
  const [catUrl, setCatUrl] = useState(null);
  const params = useParams();

  useEffect(async () => {
    setIsLoading(true);
    const all = await allCategories();
    setCategories(all);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (idCat && categories) {
      const currentCategory = categories.find((item) => item.id === idCat);
      if (!currentCategory) {
        let [firstCategory] = categories;
        return setCatUrl(
          `/categoria/${firstCategory.id}/${firstCategory.slug}`
        );
      }
      let cat = `/categoria/${idCat}/${currentCategory.slug}`;
      setCatUrl(cat);
    }
  }, [idCat]);

  useEffect(() => {
    if (window.location.pathname !== '/') {
      return setButtonGoBack(true);
    }
    return setButtonGoBack(false);
  }, [params]);

  return (
    <div>
      {!isLoading && categories ? (
        <div>
          <div id='top'>
            <div id='noise'>
              <Header />
              <Intro />
            </div>
          </div>
          <div id='children'>
            <Container>
              {buttonGoBack && <GoBack catUrl={catUrl} />}
              {children}
            </Container>
          </div>
          <Footer />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Layout;
