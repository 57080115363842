/* eslint-disable implicit-arrow-linebreak */
const axios = require('axios');

const parentCategoryId = 18;

export const api = axios.create({
  baseURL: 'https://blog.b4you.com.br/index.php/wp-json/wp/v2/',
});

export const allCategories = () =>
  api
    .get(`/categories?parent=${parentCategoryId}`)
    .then((response) => response.data)
    .catch((err) => err);

export const uniqueCategory = (id) =>
  api
    .get(`/cpt_helpdesk?categories=${id}`)
    .then((response) => response.data)
    .catch((err) => err);

export const uniquePost = (id) =>
  api
    .get(`/cpt_helpdesk/${id}`)
    .then((response) => response.data)
    .catch((err) => err);

export const searchData = (data) =>
  api
    .get(`/search?search=${data}&subtype=cpt_helpdesk`)
    .then((response) => response.data)
    .catch((err) => err);

export default api;
