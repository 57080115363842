import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../images/Logotipo.png';

function Footer() {
  return (
    <footer id='footer'>
      <Container>
        <Row className='justify-center align-items-center'>
          <Col md={6} sm={12} className='left'>
            <div className='text-left'>
              <img src={logo} alt='b4you' className='mb-3' />
              <div>© 2023 Todos direitos reservados.</div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className='wrap'>
              <a href='https://www.b4you.com.br'>
                <i className='las la-external-link-alt' />
                Ir para o site
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
