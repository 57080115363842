import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCategories } from '../providers/contextCategories';
import images from '../images';

function PageHelpDesk() {
  const { categoriesContext } = useCategories();
  const { categories } = categoriesContext;

  return (
    <div id='categorie'>
      <Row>
        {categories.map((category, index) => (
          <Col key={category.id} className='categorie' lg={12} md={12}>
            <Link to={`/categoria/${category.id}/${category.slug}`}>
              <img src={images[index]} alt='' style={{ maxWidth: '90px' }} />
              <div className='name'>{category.name}</div>
              <div className='span'>VER MAIS</div>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default PageHelpDesk;
