/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
import { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Container,
  ListGroupItem,
  NavItem,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../Loader';
import { uniquePost, uniqueCategory } from '../providers/api';
import { useCategories } from '../providers/contextCategories';

const dateRegex =
  // eslint-disable-next-line no-useless-escape
  /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

const aspas1 = '&#8220;';
const aspas2 = '&#8221;';
const aspas3 = '&#8216;';
const aspas4 = '&#8217;';

function SinglePost() {
  const { selectedCategoriesContext, selectedPostContext, selectedIdCat } =
    useCategories();
  const { currentCategory, setCurrentCategory } = selectedCategoriesContext;
  const { idCat, setIdCat } = selectedIdCat;
  const { currentPost, setCurrentPost } = selectedPostContext;
  const [validateDate, setValidateDate] = useState('');
  const [fetching, setFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { idPost } = useParams();

  useEffect(async () => {
    const post = await uniquePost(idPost);
    setCurrentPost(post);
    if (!currentCategory) {
      const categories = await uniqueCategory(post.categories[0]);
      setCurrentCategory(categories);
    }
    setIdCat(post.categories[0]);
    const validatingDate = post.date.match(dateRegex);
    setValidateDate(
      `${validatingDate[7]}/${validatingDate[5]}/${validatingDate[1]} às ${validatingDate[13]}`
    );
    setIsLoading(false);
    setFetching(false);
  }, [idPost]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        currentCategory && (
          <Row className='archive-posts-row'>
            <Col xs={12} lg={4} className='category-wrap'>
              <div className='category-content'>
                <div className='category-head articles-head'>
                  <h3>Artigos Relacionados</h3>
                </div>
                <div className='ul'>
                  {currentCategory.slice(0, 6).map((post) => (
                    <div key={post.id}>
                      {post.id !== +idPost && (
                        <ListGroupItem className='listGroup'>
                          <Link
                            to={`/post/${post.id}/${post.slug}`}
                            onClick={() => {
                              setFetching(true); /*
                                setCurrentPost(null); */
                            }}
                          >
                            {post.title.rendered
                              .replace(aspas1, '"')
                              .replace(aspas2, '"')
                              .replace(aspas3, "'")
                              .replace(aspas4, "'")}
                          </Link>
                        </ListGroupItem>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col xs={12} lg={8} className='post-wrap single-post'>
              {fetching ? (
                <Loader />
              ) : (
                <Card className='card-post'>
                  <div className='category-head'>
                    <h3>
                      {currentPost.title.rendered
                        .replace(aspas1, '"')
                        .replace(aspas2, '"')
                        .replace(aspas3, "'")
                        .replace(aspas4, "'")}
                    </h3>
                  </div>
                  <Row className='content-wrap'>
                    <Col xs={12}>
                      <Card.Text className='text-description'>
                        <div
                          className='Container'
                          dangerouslySetInnerHTML={{
                            __html: currentPost.content.rendered,
                          }}
                        />
                      </Card.Text>
                      <div className='written-by'>
                        <div>
                          <div className='post-date'>
                            <h5>Atualizado em: {validateDate}</h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
        )
      )}
    </div>
  );
}

export default SinglePost;
