import React from 'react';
import { useHistory } from 'react-router-dom';

function GoBack({ catUrl }) {
  const history = useHistory();

  function handleClick(catUrl) {
    const url = history.location.pathname.split('/');

    if (url.includes('post')) {
      history.goBack();
    } else {
      history.push('/');
    }
  }

  return (
    <button
      type='button'
      onClick={() => handleClick(catUrl)}
      className='mb-3 goback'
    >
      <i className='las la-angle-left' />
      <div className='text'>Voltar</div>
    </button>
  );
}

export default GoBack;
