import React from 'react';

function Loader() {
  return (
    <div id='box-loader'>
      <div id='loader' />
      <i className='bx bx-loader-alt bx-spin' />
      <div className='loader-message'>Carregando...</div>
    </div>
  );
}

export default Loader;
