import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import logoBranca from '../images/Logotipo-branco.png';

function Header() {
  return (
    <header id='header'>
      <Container>
        <div className='d-flex justify-content-between align-items-center header-wrap'>
          <Link to='/'>
            <img src={logoBranca} alt='b4you' />
          </Link>
          <div className='right-message'>Central de ajuda</div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
