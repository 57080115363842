import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { searchData } from '../providers/api';
import { useCategories } from '../providers/contextCategories';

const aspas1 = '&#8220;';
const aspas2 = '&#8221;';
const aspas3 = '&#8216;';
const aspas4 = '&#8217;';

function Intro() {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [fetchResults, setFetchResults] = useState([]);
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const onSubmit = (e) => {
    e.preventDefault();
    history.push(`/buscar/${search}`);
    setFetchResults([]);
    setSearch('');
  };

  useEffect(async () => {
    if (search.length === 0) {
      setFetchResults([]);
    } else {
      const data = await searchData(search);
      setFetchResults(data);
    }
  }, [search]);

  return (
    <div
      id='intro'
      className='d-flex justify-content-between align-items-center'
    >
      <Container>
        <h1 className='text-center' style={{ marginBottom: '40px' }}>
          Como podemos ajudar?
        </h1>
        <Container>
          <form onSubmit={onSubmit}>
            <Row>
              <div className='input-wrap'>
                <Form.Control
                  type='text'
                  className='input'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='Procurar...'
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
                <ul className='floating-results'>
                  {fetchResults.map((item) => {
                    return (
                      <li>
                        <Link
                          to={`/post/${item.id}/${item.url.split('/')[5]}`}
                          onClick={() => {
                            setFetchResults([]);
                            setSearch('');
                          }}
                        >
                          {item.title
                            .replace(aspas1, '"')
                            .replace(aspas2, '"')
                            .replace(aspas3, "'")
                            .replace(aspas4, "'")}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <Button type='submit'>BUSCAR</Button>
            </Row>
          </form>
        </Container>
      </Container>
    </div>
  );
}

export default Intro;
