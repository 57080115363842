/* eslint-disable global-require */
const images = {
  0: require('./images/icons/0.svg').default,
  1: require('./images/icons/1.svg').default,
  2: require('./images/icons/2.svg').default,
  3: require('./images/icons/3.svg').default,
  4: require('./images/icons/4.svg').default,
  5: require('./images/icons/5.svg').default,
};

export default images;
