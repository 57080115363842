import Routes from './Routes';
import './styles/custom.scss';
import CategoriesProvider from './providers/contextCategories';

function App() {
  return (
    <CategoriesProvider>
      <Routes />
    </CategoriesProvider>
  );
}

export default App;
