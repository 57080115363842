import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Loader from '../Loader';
import { searchData } from '../providers/api';

const aspas1 = '&#8220;';
const aspas2 = '&#8221;';
const aspas3 = '&#8216;';
const aspas4 = '&#8217;';

function ResultSearch() {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchResults, setFetchResults] = useState([]);
  const { searchParam } = useParams();

  useEffect(async () => {
    setFetchResults(await searchData(searchParam));
    setIsLoading(false);
  }, [searchParam]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        !fetchResults.length && (
          <div className='no-result'>Nenhum resultado encontrado.</div>
        )
      )}
      <div>
        {fetchResults.map((post) => (
          <div key={post.id}>
            <Row className='wrap-search'>
              <Col
                lg={8}
                md={12}
                className='category-wrap result-search'
                style={{ margin: '0 auto' }}
              >
                <Link to={`/post/${post.id}/${post.url.split('/')[5]}`}>
                  <div className='category-content'>
                    <div className='category-head'>
                      <h4 style={{ margin: '0 auto' }}>
                        {post.title
                          .replace(aspas1, '"')
                          .replace(aspas2, '"')
                          .replace(aspas3, "'")
                          .replace(aspas4, "'")}
                      </h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResultSearch;
