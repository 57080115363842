import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PageHelpDesk from './pages/PageHelpDesk';
import ArchivePosts from './pages/ArchivePosts';
import SinglePost from './pages/SinglePost';
import ResultSearch from './pages/ResultSearch';
import Layout from './components/Layout';

function Routes() {
  const routes = [
    { url: '/', component: PageHelpDesk },
    {
      url: '/categoria/:idCategories/:slugCategory',
      component: ArchivePosts,
    },
    {
      url: '/post/:idPost/:slugPost',
      component: SinglePost,
    },
    {
      url: '/buscar/:searchParam?',
      component: ResultSearch,
    },
  ];

  return (
    <Router>
      <Layout>
        <Switch>
          {routes.map((data) => (
            <Route
              key={data.url}
              path={data.url}
              component={data.component}
              exact
            />
          ))}
        </Switch>
      </Layout>
    </Router>
  );
}

export default Routes;
