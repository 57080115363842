import { useEffect, useState } from 'react';
import { Row, Col, ListGroupItem, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { uniqueCategory } from '../providers/api';
import { useCategories } from '../providers/contextCategories';
import Loader from '../Loader';

const aspas1 = '&#8220;';
const aspas2 = '&#8221;';
const aspas3 = '&#8216;';
const aspas4 = '&#8217;';

function ArchivePosts() {
  const { categoriesContext, selectedCategoriesContext } = useCategories();
  const { categories } = categoriesContext;
  const { currentCategory, setCurrentCategory } = selectedCategoriesContext;
  const [isLoading, setIsLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const { idCategories, slugCategory } = useParams();

  useEffect(async () => {
    setFetching(true);
    const category = await uniqueCategory(idCategories);
    setCurrentCategory(category);
    setFetching(false);
    setIsLoading(false);
  }, [idCategories]);

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        currentCategory && (
          <Row className='archive-posts-row'>
            <Col xs={12} lg={4} className='category-wrap'>
              <div className='category-content'>
                <div className='category-head'>
                  <h3>Categorias</h3>
                </div>
                <div className='ul'>
                  {categories.map((category) => (
                    <ListGroupItem key={category.id} className='listGroup'>
                      <Link
                        className={
                          +idCategories === category.id ? 'active' : ''
                        }
                        to={`/categoria/${category.id}/${category.slug}`}
                      >
                        {category.name}
                      </Link>
                    </ListGroupItem>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={12} lg={8} className='post-wrap archive-posts'>
              {fetching ? (
                <Loader />
              ) : currentCategory.length !== 0 ? (
                currentCategory.map((post) => (
                  <Link
                    key={post.id}
                    to={`/post/${post.id}/${post.slug}`}
                    className='link-card-post'
                  >
                    <Card key={post.title.rendered} className='card-post'>
                      <div className='category-head'>
                        <Card.Title>
                          {post.title.rendered
                            .replace(aspas1, '"')
                            .replace(aspas2, '"')
                            .replace(aspas3, "'")
                            .replace(aspas4, "'")}
                        </Card.Title>
                      </div>
                      <Row className='content-wrap'>
                        <Col xs={12}>
                          <Card.Text className='text-description'>
                            <div
                              className='Container'
                              dangerouslySetInnerHTML={{
                                __html: post.excerpt.rendered,
                              }}
                            />
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card>
                  </Link>
                ))
              ) : (
                <div className='w-100 h-100 d-flex align-items-center gx-5'>
                  <div className='no-cat d-block m-auto'>
                    Essa categoria não possui artigos.
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )
      )}
    </div>
  );
}

export default ArchivePosts;
