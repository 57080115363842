/* eslint-disable comma-dangle */
import { createContext, useContext, useState, useMemo } from 'react';

export const CategoriesContext = createContext();
export const useCategories = () => useContext(CategoriesContext);

function CategoriesProvider(props) {
  const [categories, setCategories] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentPost, setCurrentPost] = useState(null);
  const [idCat, setIdCat] = useState(null);

  const value = useMemo(
    () => ({
      categoriesContext: { categories, setCategories },
      selectedCategoriesContext: {
        currentCategory,
        setCurrentCategory,
      },
      selectedPostContext: {
        currentPost,
        setCurrentPost,
      },
      selectedIdCat: {
        idCat,
        setIdCat,
      },
    }),
    [categories, currentCategory, currentPost, idCat]
  );

  return <CategoriesContext.Provider value={value} {...props} />;
}

export default CategoriesProvider;
